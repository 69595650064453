import { ExtendedDomainConfiguration } from '../../domain-config/domain-config'
import { Currency, CURRENCY_COOKIE } from '@/types/Page'
import { fromByteArray } from 'base64-js'
import Cookies from 'js-cookie'
import { Duration, Month, SEARCH_PARAMETER_KEYS, SearchParameters } from '@/types/Search'
import { ReactNode } from 'react'

import { ReactComponentLike } from 'prop-types'
import { BreakPoint } from '@/types/Misc'

export const FAVORITE_HOTELS_LOCALSTORAGE_KEY = 'fit-fav'

const BREAKPOINTS = {
  [BreakPoint.TINY]:  489,
  [BreakPoint.SMALL_MOBILE]:  639, // [BreakPoint.MOBILE
  [BreakPoint.MOBILE]:  767,
  [BreakPoint.TABLET]:  1023,
  [BreakPoint.DESKTOP]:  1279,
  [BreakPoint.LARGE_DESKTOP]:  1535,
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: Record<string, any>[] | undefined
  }
}

const abortControllers = new Map<string, AbortController>()

export function getTimeSearchQueryGeneral(
  selectedOptionDuration: Duration | undefined,
  flexibleTravelPeriodSelected: boolean | undefined,
  selectedOptionCalendarFlexibility: Duration | undefined,
  selectedOptionPeriods: Month[] | undefined,
  startDate: string | undefined,
  endDate: string | undefined,
): SearchParameters {
  function getDuration(): SearchParameters {
    return selectedOptionDuration
      ? {
          [SEARCH_PARAMETER_KEYS.dmin]: selectedOptionDuration.min.toString(),
          [SEARCH_PARAMETER_KEYS.dmax]: selectedOptionDuration.max.toString(),
        }
      : ({} as Record<SEARCH_PARAMETER_KEYS, string>)
  }

  const dateFlexibility = selectedOptionCalendarFlexibility
    ? selectedOptionCalendarFlexibility.max.toString()
    : '0'

  if (flexibleTravelPeriodSelected) {
    return {
      [SEARCH_PARAMETER_KEYS.month]: selectedOptionPeriods?.map((p) => p.month).join('_'),
      ...getDuration(),
    }
  } else {
    const dateFlexibilityValue =
      dateFlexibility !== '0' ? { [SEARCH_PARAMETER_KEYS.dateFlexibility]: dateFlexibility } : {}

    const exactDate = {
      [SEARCH_PARAMETER_KEYS.dateExact]: '1',
      ...dateFlexibilityValue,
    }
    if (startDate && endDate) {
      exactDate[SEARCH_PARAMETER_KEYS.df] = startDate.split('T')[0].replace(/-/g, '')
      exactDate[SEARCH_PARAMETER_KEYS.dt] = endDate.split('T')[0].replace(/-/g, '')
    }
    return exactDate
  }
}

export function onBreakPoint(): BreakPoint {
  for(const [key, value] of Object.entries(BREAKPOINTS)) {
    if(smallerOrEqualThanScreenWidth(value)) {
      return key as BreakPoint
    }
  }
  return BreakPoint.LARGE_DESKTOP
}

export function onMobile(): boolean {
  return smallerOrEqualThanScreenWidth(767)
}

export function onSmallMobile(): boolean {
  return smallerOrEqualThanScreenWidth(640)
}

export function onTouchDevice(): boolean {
  if (typeof window === 'undefined') {
    return false
  }

  return 'ontouchstart' in window
}

export function onMobileOrTablet(): boolean {
  return smallerOrEqualThanScreenWidth(1023)
}

export function smallerOrEqualThanScreenWidth(width: number): boolean {
  if (typeof window === 'undefined') {
    return false
  }

  return window.matchMedia(`(max-width: ${width}px)`).matches
}

export function loadLikedHotels(): string[] {
  try {
    return JSON.parse(localStorage.getItem(FAVORITE_HOTELS_LOCALSTORAGE_KEY) || '[]') as string[]
  } catch {
    return []
  }
}

export function storeLikedHotels(likedHotels: string[]): void {
  try {
    localStorage.setItem(
      FAVORITE_HOTELS_LOCALSTORAGE_KEY,
      likedHotels ? JSON.stringify(likedHotels) : '[]',
    )
    window.dispatchEvent(new Event('storage'))
  } catch {}
}

export function toUtfBase64String(string: string): string {
  const utf8Encode = new TextEncoder()
  return fromByteArray(utf8Encode.encode(string))
}

export function getCurrency(config: ExtendedDomainConfiguration): Currency {
  return (Cookies.get(CURRENCY_COOKIE) as Currency) || config.currency
}

function preventScroll(): void {
  window.scrollTo(0, 0)
}

export function lockScrollableBody(): void {
  document.body.style.top = `-${window.scrollY}px`
  document.body.style.position = 'fixed'
  document.body.style.width = '100%'
  document.addEventListener('scroll', preventScroll)
}

export function unlockScrollableBody(): void {
  const scrollY = document.body.style.top
  document.body.style.position = ''
  document.body.style.top = ''
  document.body.style.width = ''
  document.body.style.overflow = 'unset'
  if (scrollY) {
    window.scrollTo(0, parseInt(scrollY || '0') * -1)
  }
  document.removeEventListener('scroll', preventScroll)
}

export function formatHotelUrl(url: string): string {
  if (url?.startsWith('http')) {
    return url
  }

  return `${location.protocol}//${location.host}${url.replace('//', '/')}`
}

export function renderXTimes<T>(x: number, Comp: ReactComponentLike, props?: T): ReactNode {
  return [...new Array<unknown>(x)].map((e, index) => <Comp key={index} {...props} />)
}


export function getHotelName(name: string): [string, string] {
  const splitName = name.split(' ')

  if (splitName.length > 1) {
    return [splitName.slice(0, splitName.length - 1).join(' '), splitName[splitName.length - 1]]
  } else {
    return [splitName[0], '']
  }
}

export function fetchWithAbort(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
  function getKey() {
    return new URL(input.toString()).pathname
  }

  function getSignal() {
    const controller = new AbortController()

    abortControllers.set(getKey(), controller)

    return controller.signal
  }

  function abortRunningRequest() {
    abortControllers.get(getKey())?.abort('Aborted by new request')
    abortControllers.delete(getKey())
  }

  abortRunningRequest()

  return fetch(input, { ...init, signal: getSignal() })
}