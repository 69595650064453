import { UserParameterSlot } from '@/lib/ab-tests/test-definitions'

export enum TestVariantGroupLogic {
  FIRST_HALF_SECOND_HALF,
  EVEN_ODD,
}

export interface TestVariantProps {
  Enabled: boolean
  ServerSide: boolean
  name: string
  GroupLogic?: TestVariantGroupLogic
  slot: UserParameterSlot
}

export enum TestVariantGroup {
  CONTROL,
  TEST,
}
