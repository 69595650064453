import { SearchItem, SearchResultItemCategory } from '@/types/Page'

export enum SEARCH_PARAMETER_KEYS {
  t = 't',
  tt = 'tt',
  mp = 'mp',
  rt = 'rt',
  prop = 'prop',
  r = 'r',
  cg = 'cg',
  c = 'c',
  h = 'h',
  l = 'l',
  dmin = 'dmin',
  dmax = 'dmax',
  month = 'month',
  dateFlexibility = 'dateFlexibility',
  dateExact = 'dateExact',
  df = 'df',
  dt = 'dt',
  b = 'b',
  rating = 'rating',
  stars = 'smin',
  pl = 'pl',
  o = 'o',
  lat_nw = 'lat_nw',
  lng_nw = 'lng_nw',
  lat_se = 'lat_se',
  lng_se = 'lng_se',
  allotment = 'allotment',
}

export type SearchParameters = Partial<Record<SEARCH_PARAMETER_KEYS, string | number>>

export interface Month {
  month: number | undefined
  name: string
}

export interface Duration {
  min: number
  max: number
  label: string
  withoutPlusMinus?: boolean
}
