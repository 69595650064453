import Cookies from 'js-cookie'
import { TEST_VARIANTS_PROPS, TestVariant } from '@/lib/ab-tests/test-definitions'
import { TestVariantGroup, TestVariantGroupLogic, TestVariantProps } from '@/lib/ab-tests/types'

export const TEST_VARIANTS_COOKIE = 'test_variants'
const TEST_VARIANTS_COOKIE_LENGTH = 32

function getGroupLegacy(randomNumber: number, groupLogic: TestVariantGroupLogic): TestVariantGroup {
  switch (groupLogic) {
    case TestVariantGroupLogic.EVEN_ODD:
      return randomNumber % 2 === 0 ? TestVariantGroup.CONTROL : TestVariantGroup.TEST

    case TestVariantGroupLogic.FIRST_HALF_SECOND_HALF:
      return randomNumber <= 50 ? TestVariantGroup.CONTROL : TestVariantGroup.TEST
  }
}

export function getTestVariantsString(cookieString: string, includeClientSide: boolean): string {
  const result: string[] = []

  for (const variantKey of Object.values(TestVariant) as TestVariant[]) {
    if (TEST_VARIANTS_PROPS.hasOwnProperty(variantKey)) {
      const variant = TEST_VARIANTS_PROPS[variantKey]
      if ((variant.ServerSide || includeClientSide) && variant.Enabled) {
        const inTestGroup = isInTestGroup(variantKey, cookieString)
        const propertyString = `${TEST_VARIANTS_PROPS[variantKey].name.toLowerCase()}-${inTestGroup ? 'test' : 'control'}`
        result.push(propertyString)
      }
    }
  }

  return result.length > 0 ? result.sort().join('_') : 'none'
}

export function getABTestAnalyticsUserProperties(cookieString: string): { [key: string]: string } {
  const result = {}

  Object.keys(TEST_VARIANTS_PROPS).forEach((entry) => {
    const variant = entry as unknown as TestVariant

    const props = TEST_VARIANTS_PROPS[variant]
    const propertyName = `ab_test_${props.slot}`

    if (result.hasOwnProperty(propertyName)) {
      console.error(`Duplicate slot ${props.slot} found in test variants; Skiping ${props.name}`)
      return
    }

    result[`ab_test_${props.slot}`] = shortenFromStart(
      `${props.name}_${isInTestGroup(variant, cookieString) ? 'test' : 'control'}`,
      36,
    )
  })

  return result
}

function isNewCookieValue(cookieValue: string): boolean {
  return cookieValue !== undefined && cookieValue.length === 32
}

function isOldCookieValue(cookieValue: string): boolean {
  function asNumber(cookieValue: string) {
    return cookieValue !== undefined ? parseInt(cookieValue, 10) : NaN
  }

  const value = asNumber(cookieValue)

  return !isNaN(value) && value >= 1 && value <= 100
}

export function getTestVariantsCookieValue(cookieValue: string | undefined): string {
  //Generate a string with a length of 32 characters each character is a random number between 0 and 9
  function generateNewCookieValue() {
    return Array.from({ length: TEST_VARIANTS_COOKIE_LENGTH }, () =>
      Math.floor(Math.random() * 10),
    ).join('')
  }

  if (cookieValue === undefined) {
    return generateNewCookieValue()
  }

  function migrateLegacyCookie(cookieValue: string) {
    const randomString = generateNewCookieValue()
    const parsedCookie = parseInt(cookieValue, 10)
    const firstNumberMustBeEven =
      getGroupLegacy(
        parsedCookie,
        TEST_VARIANTS_PROPS[TestVariant.ReloadSavedSearchData].GroupLogic!,
      ) === TestVariantGroup.CONTROL
    const secondNumberMustBeEven =
      getGroupLegacy(
        parsedCookie,
        TEST_VARIANTS_PROPS[TestVariant.DirectlyBookable].GroupLogic!,
      ) === TestVariantGroup.CONTROL

    function getEvenRandomNumberBetween0And9() {
      return (Math.floor(Math.random() * 5) * 2).toString()
    }

    function getOddRandomNumberBetween0And9() {
      return (Math.floor(Math.random() * 5) * 2 + 1).toString()
    }

    const firstString = firstNumberMustBeEven
      ? getEvenRandomNumberBetween0And9()
      : getOddRandomNumberBetween0And9()
    const secondString = secondNumberMustBeEven
      ? getEvenRandomNumberBetween0And9()
      : getOddRandomNumberBetween0And9()

    return firstString + secondString + randomString.slice(2)
  }

  if (isOldCookieValue(cookieValue)) {
    return migrateLegacyCookie(cookieValue)
  }

  if (isNewCookieValue(cookieValue)) {
    return cookieValue
  }

  return generateNewCookieValue()
}

export function allPossibleServerSideTestVariantCombinations(): string[] {
  // Object only used to calculate possible values for {testVariants} in middleware path
  // Extract all properties from TEST_VARIANTS_PROPS with Enabled and ServerSide set to true
  const obj: { [key: string]: boolean } = {}

  Object.keys(TEST_VARIANTS_PROPS).forEach((key) => {
    const variant = TEST_VARIANTS_PROPS[key] as TestVariantProps
    if (variant.Enabled && variant.ServerSide) {
      obj[TEST_VARIANTS_PROPS[key as unknown as TestVariant].name] = true
    }
  })

  // Get the sorted array of property names
  const sortedProperties = Object.keys(obj).sort()

  const allPaths: string[] = ['']

  // Iterate through each property of the object
  sortedProperties.forEach((key) => {
    const newPaths: string[] = []

    // Append each existing path with the current property in both true and false states
    allPaths.forEach((path) => {
      const separator = path === '' ? '' : '_'
      newPaths.push(`${path}${separator}${key}-test`, `${path}${separator}${key}-control`)
    })

    // Update the array with the new paths
    allPaths.length = 0
    allPaths.push(...newPaths)
  })

  const finalPaths = allPaths.filter((str) => str.trim() !== '')
  // Sort the resulting array alphabetically
  return finalPaths.length > 0 ? finalPaths.sort() : ['none']
}

export function isInTestGroup(testVariant: TestVariant, cookieString?: string): boolean {
  if (testVariant >= TEST_VARIANTS_COOKIE_LENGTH) {
    console.error(
      `Test variant ${testVariant} is out of bounds. The maximum test variant is ${TEST_VARIANTS_COOKIE_LENGTH - 1}`,
    )

    return false
  }

  const randomString = cookieString || getTestVariantsCookieValue(Cookies.get(TEST_VARIANTS_COOKIE))
  return +randomString[testVariant] % 2 === 1
}

function shortenFromStart(input: string, maxLength: number): string {
  if (input.length <= maxLength) {
    return input
  }

  return input.slice(input.length - maxLength)
}
