import { BasePage, BreadcrumbItem, HotelItem } from '@/types/Page'
import { ReactNode } from 'react'

export interface AgencyData {
  AgencyNumber: string
  PhoneE164: string
  PhoneDisplay: string
}

export interface HotelRatingsData {
  page: number
  numberOfPages: number
  numberOfRatings: number
  ratingOverall: number
  ratingHotel: number
  ratingService: number
  ratingTreatments: number
  recommendationRate: number
  ratingFood: number
  ratingSpa: number
  ratings: HotelRating[]
  keywords: string[]
}

export interface HotelRating {
  travelDuration: string
  travellerAge: string
  travellerType: string
  summary: string
  ratingOverall: number
  textOverall: string
  ratingHotel: number
  textHotel: string
  ratingService: number
  textService: string
  ratingFood: number
  textFood: string
  ratingSpa: number
  textSpa: string
  ratingTreatments: number
  textTreatments: string
  travelDate: string
  travellerName: string
  comment: string
}

export enum SiteMapType {
  PAGES = 'pages',
  HOTELS = 'hotels',
  SITEMAP = 'sitemap',
}

export interface SiteMapData {
  domain: string
  Domain: string
  slugs: string[]
  Sitemap: HotelsSiteMap | PagesSiteMap
}

export interface HotelsSiteMap {
  Headline: string
  Hotels: SiteMapHotel[]
}

export interface PagesSiteMap {
  Headline: string
  Pages: SiteMapPages[]
}

export interface SiteMapPages {
  Title: string
  Url: string
}

export interface SiteMapHotel {
  Title: string
  Url: string
}

interface Feature {
  icon: string
  text: string
}

export interface FeatureGroup {
  group: string
  icon: string
  features: Feature[]
}

export interface Program {
  programCode: string
  features: string
  price: string
  priceOriginal: string
  name: string
  descriptions: ProgramDescription[]
  indications: string[]
  selectedBeds: number
  selectedDuration: number
  selectedMealPlanCode: number
  selectedRoomCode: string
  bookingcode: number
  selectedDateFrom: string
  durations: number[]
  mealPlanCodes: { key: number; value: string }[]
  beds: number[]
  roomCodes: { key: string; value: string }[]
  badges: string[]
}

export interface ProgramDescription {
  headline: string
  text: string
}

export interface Description {
  headline: string
  text: string
  isHidden: boolean
}

export interface Room {
  [key: string]: string | string[] | Feature[] | RoomImage[] | FeatureGroup[] | undefined
  code: string
  name: string
  description: string
  roomImages: RoomImage[]
  popularFeatures: Feature[]
  features: FeatureGroup[]
}

export interface RoomImage {
  caption: string | null
  fileName: string
}

export interface RoomWithPrice extends Room {
  price?: string
}

export interface Extra {
  [key: string]: string
  name: string
  bookingcode: string
  description: string
  price: string
  image: string
}

export interface Airport {
  code: string
  name: string
  latitude: number
  longitude: number
  distance: number
  duration: number
}

export interface Trainstation {
  code: string
  name: string
  latitude: number
  longitude: number
  distance: number
  duration: number
}

export interface Awards {
  name: string
  imageFileName: string
  imageRatio: number
}

export interface HotelData {
  dataLayer: string
  numberOfImages: number
  airports: Airport[]
  awards: Awards[]
  trainStations: Trainstation[]
  popularities: string[]
  destinations: string[]
  uniqueSellingPoints: string[]
  hotelCategoryText: string
  hotelCategoryStars: number
  discount: string
  hotelName: string
  subtitle: string
  primaryText: string
  text: string
  descriptionShort: string | null
  popularFeatures: Feature[]
  features: FeatureGroup[]
  programs: Program[]
  descriptions: Description[]
  opinion: Description
  rooms: Room[]
  extras: Extra[]
  latitude: number
  longitude: number
  similarHotels: HotelItem[]
  nearbyHotels: HotelItem[]
  hotelCode: string
  images: string[]
  ratingValue: number
  ratingText: string
  isKmwHotel: boolean
  recommendationRate: number
  numberOfRatings: number
  descriptionRoom: string
  breadcrumb: BreadcrumbItem[]
}

export interface HotelPageData extends BasePage {
  hotel: HotelData
}

export enum BreakPoint {
  TINY = 'TINY',
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
  DESKTOP = 'DESKTOP',
  LARGE_DESKTOP = 'LARGE_DESKTOP',
  SMALL_MOBILE = 'SMALL_MOBILE',
}

export type ProgramUpdate = Partial<Program> & { programCode: string }

export interface ProgramsUpdate {
  programs: ProgramUpdate[]
  additionalPrograms: ProgramUpdate[]
}

export interface WizardOffersDate {
  offerId: string
  date: string
  price: number
  priceString: string
  totalPriceString: string
  allotment: boolean
}

interface WizardOffersOption<T> {
  value: T
  priceChange: number
  priceChangeAsString: string
}

interface CalendarHint {
  month: number
  minDuration: number
}

export interface WizardOffers {
  dates: WizardOffersDate[]
  beds: WizardOffersOption<number>[]
  roomCodes: WizardOffersOption<string>[]
  durations: WizardOffersOption<number>[]
  mealPlanCodes: WizardOffersOption<number>[]
  calendarHints: CalendarHint[]
}

export interface WizardAvailability {
  priceString: string
  totalPriceString: string
  bookingEnabled: boolean
  bookingRequestEnabled: boolean
  priceChange: boolean
  reservationPossible: boolean
  flightPossible: boolean
  bookingCode: string
}

export interface HotelImage {
  filename: string
  caption: string | null
  height: number
  width: number
}

export type ProgramRelatedElement = { code: string; element: ReactNode }
export type ProgramRelatedElements = Record<string, ReactNode>

export enum TrackingDataEventTypes {
  LANDING_PAGE_VIEW = 9,
  LIKE = 7,
  HOTEL_PAGE_VIEW = 4,
  HOTEL_IMPRESSION_LANDING_PAGE = 10,
  HOTEL_IMPRESSION_SEARCH = 11,
}

interface TrackingData {
  type: TrackingDataEventTypes
}

export interface TrackPageViewParams extends TrackingData {
  pageId: string
  type: TrackingDataEventTypes.LANDING_PAGE_VIEW
}

export interface TrackHotelPageViewParams extends TrackingData {
  hotelCode: string
  type: TrackingDataEventTypes.HOTEL_PAGE_VIEW
}

export interface TrackHotelImpressionLandingPageParams extends TrackingData {
  hotelCodes: string[]
  type: TrackingDataEventTypes.HOTEL_IMPRESSION_LANDING_PAGE
}

export interface TrackHotelImpressionSearchParams extends TrackingData {
  hotelCodes: string[]
  type: TrackingDataEventTypes.HOTEL_IMPRESSION_SEARCH
}

export interface TrackLikeParams extends TrackingData {
  hotelCode: string
  type: TrackingDataEventTypes.LIKE
}

export interface TrackLikeParams extends TrackingData {
  hotelCode: string
  type: TrackingDataEventTypes.LIKE
}

export type KeysWithObjects<T> = {
  [K in keyof T]: T[K] extends object
    ? T[K] extends Array<any>
      ? T[K][number] extends object
        ? K
        : never
      : K
    : never
}[keyof T]

type ExtractStringKeys<T> = {
  [K in keyof T]: T[K] extends string ? K : never
}[keyof T]

type ExtractNestedObjectKeys<T> =
  T extends Array<infer U> ? (U extends object ? keyof U : never) : never

export type CombineKeys<T> = ExtractStringKeys<T> | ExtractNestedObjectKeys<T[keyof T]>
