'use client'

import { MutableRefObject, RefObject, useEffect, useRef, useState } from 'react'
import { onBreakPoint, onMobile, onMobileOrTablet } from '@/lib/utils/utils'
import { BreakPoint } from '@/types/Misc'
import { isWhitelabel } from '@/lib/utils/isWhitelabel'

export function useIsWhiteLabel(): boolean {
  const [isWhitelabelPage, setIsWhitelabelPage] = useState(false)

  useEffect(() => {
    setIsWhitelabelPage(isWhitelabel(location.hostname))
  })

  return isWhitelabelPage
}

export function usePersistedPageLoaded(action: () => void): void {
  useEffect(() => {
    function handleVisibilityChange(event: PageTransitionEvent): void {
      if (event.persisted) {
        action()
      }
    }

    window.addEventListener('pageshow', handleVisibilityChange)

    return () => window.removeEventListener('pageshow', handleVisibilityChange)
  }, [])
}

export function useOnMobileOrTablet(): boolean {
  const [onMobile, setOnMobile] = useState(false)

  useEffect(() => {
    function updateOnMobileOrTablet(): void {
      setOnMobile(onMobileOrTablet())
    }

    window.addEventListener('resize', updateOnMobileOrTablet)

    updateOnMobileOrTablet()

    return () => window.removeEventListener('resize', updateOnMobileOrTablet)
  }, [])

  return onMobile
}

export function useOnMobile(): boolean {
  const [onMobileState, setOnMobileState] = useState(onMobile())

  useEffect(() => {
    function updateOnMobileOrTablet(): void {
      setOnMobileState(onMobile())
    }

    window.addEventListener('resize', updateOnMobileOrTablet)

    updateOnMobileOrTablet()

    return () => window.removeEventListener('resize', updateOnMobileOrTablet)
  }, [])

  return onMobileState
}

export function useOnBreakPoint(): BreakPoint {
  const [onBreakPointValue, setOnBreakPointValue] = useState(onBreakPoint())

  useEffect(() => {
    function updateOnBreakPoint(): void {
      setOnBreakPointValue(onBreakPoint())
    }

    window.addEventListener('resize', updateOnBreakPoint)

    updateOnBreakPoint()

    return () => window.removeEventListener('resize', updateOnBreakPoint)
  }, [])

  return onBreakPointValue
}

export function useIsContentOverflowingHeight(
  ref: MutableRefObject<HTMLDivElement | null>,
): boolean {
  const [isOverflowing, setIsOverflowing] = useState(false)
  const observerRef: MutableRefObject<ResizeObserver | null> = useRef<ResizeObserver>(null)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    function checkOverflow(): void {
      const element = ref.current
      if (element) {
        setIsOverflowing(element.scrollHeight > element.clientHeight)
      }
    }

    observerRef.current = new ResizeObserver(checkOverflow)
    observerRef.current.observe(ref.current)

    checkOverflow() // Initial check

    return () => observerRef.current?.disconnect()
  }, [ref.current])

  return isOverflowing
}

export function useOutsideClick<T extends HTMLElement>(
  ref: RefObject<T>,
  callback: () => void,
  ignoreOutsideClick: boolean,
): void {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (!ignoreOutsideClick && ref.current && !ref.current.contains(event.target as Node)) {
        callback()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback, ignoreOutsideClick])
}
