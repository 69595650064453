import { TestVariantGroupLogic, TestVariantProps } from '@/lib/ab-tests/types'

//Do not reuse indices for different tests, Maximum index id TEST_VARIANTS_COOKIE_LENGTH - 1
//After reaching the maximum index, then start over again.
export enum TestVariant {
  ReloadSavedSearchData = 0,
  DirectlyBookable = 1,
  XTimesTagged = 2,
  SearchFilterAtBottom = 3,
  FlexibleTravelPeriod = 4,
}

export enum UserParameterSlot {
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5',
  Six = '6',
  Seven = '7',
  Eight = '8',
  Nine = '9',
  Ten = '10',
}

type TestVariants = Record<TestVariant, TestVariantProps>

export const TEST_VARIANTS_PROPS: TestVariants = {
  [TestVariant.ReloadSavedSearchData]: {
    Enabled: true,
    ServerSide: false,
    GroupLogic: TestVariantGroupLogic.EVEN_ODD,
    name: 'reloadsavedsearchdata',
    slot: UserParameterSlot.One,
  },
  [TestVariant.DirectlyBookable]: {
    Enabled: true,
    ServerSide: false,
    GroupLogic: TestVariantGroupLogic.FIRST_HALF_SECOND_HALF,
    name: 'directlybookable',
    slot: UserParameterSlot.Two,
  },
  [TestVariant.XTimesTagged]: {
    Enabled: true,
    ServerSide: false,
    name: 'xtimestagged',
    slot: UserParameterSlot.Three,
  },
  [TestVariant.SearchFilterAtBottom]: {
    Enabled: true,
    ServerSide: false,
    name: 'searchfilteratbottom',
    slot: UserParameterSlot.Four,
  },
  [TestVariant.FlexibleTravelPeriod]: {
    Enabled: true,
    ServerSide: false,
    name: 'flexibletravelperiod',
    slot: UserParameterSlot.Five,
  },
}
